export default class Patch {
  constructor() {
    this.volume = null;
    this.pan = null;
    this.tone = null;
    this.synth = null;
    this.reverb = null;
    this.delay = null;
    this.envelope = null;
  }
}